<script>
	import { onMount } from 'svelte';
	import { items } from '../store.js';

	export let id = null;

	onMount(() => {
		window.scrollTo(0, 0);
	});

	$: item = $items.find((item) => {
		return item.id === parseInt(id);
	});
</script>

<main id="page">
	{#if item}
	<div class="item-block">
	    <div class="item-block-hero">
	        <div class="item-block-hero-image">
	            <span class="ani icon-{item.gfx}"></span>
	        </div>

	        <div class="item-block-hero-stats">
	            <ul class="stat-blocks">
	            	{#if item.stat_damage}
	                <li>
	                    <div class="stat-block">
	                        <div class="stat-block-number">
	                            {item.stat_damage}
	                        </div>
	                        <div class="stat-block-label">Damage</div>
	                    </div>
	                </li>
	                {/if}

	                {#if item.stat_tears}
	                <li>
	                    <div class="stat-block">
	                        <div class="stat-block-number">
	                            {item.stat_tears}
	                        </div>
	                        <div class="stat-block-label">Tears</div>
	                    </div>
	                </li>
	                {/if}

	                {#if item.stat_tear_delay}
	                <li>
	                    <div class="stat-block">
	                        <div class="stat-block-number">
	                            {item.stat_tear_delay}
	                        </div>
	                        <div class="stat-block-label">Tear Delay</div>
	                    </div>
	                </li>
	                {/if}

	                {#if item.stat_health}
	                <li>
	                    <div class="stat-block">
	                        <div class="stat-block-number">
	                            {item.stat_health}
	                        </div>
	                        <div class="stat-block-label">Health</div>
	                    </div>
	                </li>
	                {/if}

	                {#if item.stat_range}
	                <li>
	                    <div class="stat-block">
	                        <div class="stat-block-number">
	                            {item.stat_range}
	                        </div>
	                        <div class="stat-block-label">Range</div>
	                    </div>
	                </li>
	                {/if}

	                {#if item.stat_speed}
	                <li>
	                    <div class="stat-block">
	                        <div class="stat-block-number">
	                            {item.stat_speed}
	                        </div>
	                        <div class="stat-block-label">Speed</div>
	                    </div>
	                </li>
	                {/if}

	                {#if item.stat_tear_height}
	                <li>
	                    <div class="stat-block">
	                        <div class="stat-block-number">
	                            {item.stat_tear_height}
	                        </div>
	                        <div class="stat-block-label">Tear Height</div>
	                    </div>
	                </li>
	                {/if}

	                {#if item.stat_shot_speed}
	                <li>
	                    <div class="stat-block">
	                        <div class="stat-block-number">
	                            {item.stat_shot_speed}
	                        </div>
	                        <div class="stat-block-label">Shot Speed</div>
	                    </div>
	                </li>
	                {/if}
	            </ul>
	        </div>
	    </div>
	    <div class="item-block-content">
	        <div class="item-block-content-pri">
	            <div class="detail">
	                <span class="detail-label">Name</span>
	                <h2 class="detail-text">{item.name}</h2>
	            </div>

	            {#if item.description}
	            <div class="detail">
	                <span class="detail-label">
	                    In-Game Description
	                </span>
	                <span class="detail-text">{item.description}</span>
	            </div>
	            {/if}

	            {#if item.notes}
	            <div class="detail">
	                <span class="detail-label">Notes</span>
	                <span class="detail-text">{item.notes}</span>
	            </div>
	            {/if}

	            {#if item.unlock}
	            <div class="detail">
	                <span class="detail-label">How To Unlock</span>
	                <span class="detail-text">{item.unlock}</span>
	            </div>
	            {/if}
	        </div>

	        <div class="item-block-content-sec">
	        	{#if item.max_charges}
	            <div class="detail">
	                <span class="detail-label">Max Charges</span>
	                <span class="detail-text">{item.max_charges}</span>
	            </div>
	            {/if}

	            {#if item.devil_price}
	            <div class="detail">
	                <span class="detail-label">Devil Price</span>
	                <span class="detail-text">
	                    <ul class="heart-list">
	                		{#each Array(item.devil_price / 2) as heart}
					        <li>
					            <span class="icon-pickup-heart-red-sm"></span>
					        </li>
					        {/each}
					    </ul>
	                </span>
	            </div>
	            {/if}

	            {#if item.soul_hearts}
	            <div class="detail">
	                <span class="detail-label">Soul Hearts</span>
	                <span class="detail-text">
	                    <ul class="heart-list">
	                		{#each Array(item.soul_hearts / 2) as heart}
					        <li>
					            <span class="icon-pickup-heart-soul-sm"></span>
					        </li>
					        {/each}
					    </ul>
	                </span>
	            </div>
	            {/if}

	            {#if item.black_hearts}
	            <div class="detail">
	                <span class="detail-label">Black Hearts</span>
	                <span class="detail-text">
	                    <ul class="heart-list">
	                		{#each Array(item.black_hearts / 2) as heart}
					        <li>
					            <span class="icon-pickup-heart-black-sm"></span>
					        </li>
					        {/each}
					    </ul>
	                </span>
	            </div>
	            {/if}

	            {#if item.type}
	            <div class="detail">
	                <span class="detail-label">
	                    Type
	                </span>
	                <ul class="tag-list">
	                    <li>{item.type}</li>
	                </ul>
	            </div>
	            {/if}

	            <!-- {#if item.pools}
	            <div class="detail">
	                <span class="detail-label">Pools</span>
	                <ul class="tag-list">
	                    <li>{pool}</li>
	                </ul>
	            </div>
	            {/if} -->
	        </div>

	    </div>
	</div>
	{/if}
</main>
