<script>
	import { Router, Link, navigate, link } from 'svelte-routing';
	import { filter } from '../store.js';

	let search = '';

	function filterItems(e) {
		filter.set(search.toLowerCase());
		navigate('/', { replace: true });
	}

	function clearSearch() {
		search = '';
		filter.set(search);
		navigate('/', { replace: true });
	}
</script>


<Router>
	<header id="header">
        <div id="header-logo">
            <a class="ani btn-logo" href="/" use:link>
                <div class="logo">
			        <div class="logo-icon">
			            <img src="/assets/img/logo.png" alt="Sack Head Icon" />
			        </div>
			        <div class="logo-text">
			            Sack Head
			        </div>
			    </div>
            </a>
        </div>

        <div id="header-search">
            <form on:submit|preventDefault="{ filterItems }">
		        <div class="search">
		            <input
		                name="search"
		                placeholder="Search..."
		                type="text"
		                bind:value={search}>

	                {#if search}
	                <button
	                    type="button"
	                    class="search-clear"
	                    on:click={ clearSearch }>
	                    
	                    <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>
	                </button>
	                {/if}
		        </div>
		    </form>
        </div>

        <!-- <div id="header-nav">
        	<ul class="nav">
        		<li><Link to="/">Home</Link></li>
        		<li><Link to="about">About</Link></li>
        	</ul>
        </div> -->
    </header>
</Router>
