<script>
	import { Router, Route } from 'svelte-routing';
	import { onMount } from 'svelte';
	import { items } from './store.js';
	import Header from './components/Header.svelte';
	import Loader from './components/Loader.svelte';
	import Home from './routes/Home.svelte';
	import Item from './routes/Item.svelte';
	import About from './routes/About.svelte';

	export let url = '';
	let loaded = false;

	onMount(async () => {
		$items = await getItems();

		setTimeout(() => {
			loaded = true;
		}, 1000);
	});

	const getItems = async () => {
		let cachedItems = localStorage.getItem('items');

		if (cachedItems) {
			return JSON.parse(cachedItems);
		} else {
			const response = await fetch('https://rebirth-api.herokuapp.com/items');
			const data = await response.json();
			localStorage.setItem('items', JSON.stringify(data));
			return data;
		}
	}
</script>

<Header />

{#if !loaded}
	<Loader />
{/if}

<Router url="{url}">
	<Route path="about" component="{About}" />
	<Route path="item/:id" component="{Item}" />
	<Route path="/" component="{Home}" />
	<Route component="{Home}" />
</Router>


