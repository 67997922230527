<script>
	import { Router, link } from 'svelte-routing';
	import { items, filter } from '../store.js';

	$: filteredList = $items.filter((item) => {
		const description = item.description ? item.description.toLowerCase() : '';
		var tags = item.tags ? item.tags.split(',') : '';

		return item.name.toLowerCase().indexOf($filter) !== -1 ||
			description.indexOf($filter) !== -1 ||
			item.type.toLowerCase().indexOf($filter) !== -1 ||
			tags.indexOf($filter) !== -1;
	});
</script>

<main>
	{#if filteredList.length > 0}
		<ul class="item-list">
			{#each filteredList as item}
				<li>
					<div class="item-card">
				        <div class="item-card-image">
				        	<a href="item/{item.id}" use:link>
			                	<span class="icon-{item.gfx}"></span>
			            	</a>
				        </div>
				        <div class="item-card-content">
				            <h3 class="item-card-name">
			                    <a href="item/{item.id}" use:link>
			                    	{item.name}
			                    </a>
				            </h3>
				            <p class="item-card-description">
				            	{#if item.description}
				            		{item.description}
				            	{:else if item.notes}
				            		{item.notes}
				            	{:else}
				            		No description.
				            	{/if}
				            </p>
				            <span class="item-card-tag">
				                {item.type}

				                {#if item.max_charges}
				                <span>
				                    ({item.max_charges})
				                </span>
				                {/if}
				            </span>
				        </div>
				    </div>
				</li>
			{/each}
		</ul>
	{:else}
		<div class="empty-block">
            <div class="empty-block-content">
                No Items Matched Your Search.
            </div>
        </div>
	{/if}
</main>
